@import "./assets/fonts/style.css";
body {
  overflow-x: hidden;
  font-family: 'SF Pro Display Regular';
  background-color: #F3F4F8;
}
h1 {
  font-size: 48px;
  color: #fff;
  font-family: 'SF Pro Display SemiBold';
  line-height: 55px;
  margin-bottom: 30px;
} 
h2 {
  font-size: 36px;
  color: #545454;
  font-family: 'SF Pro Display SemiBold';
  line-height: 45px;
  margin-bottom: 20px;
} 
p {
  font-size: 20px;
  color: #878787;
  font-family: 'SF Pro Display Regular';
  line-height: 28px;
  margin-bottom: 20px;
}
p a,p a:hover {
    color: #F37A20;
    text-decoration: none;
    font-family: 'SF Pro Display Medium';
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
.svg-sm-logo .st0{fill:#EF5123;}
.svg-sm-logo .st1{fill:#F37A20;}
.svg-sm-logo .st2{fill:#EE3824;}

:root {
  --orange: #F37A20;
  --pink: #FFD9BD;
  --input: #F3F4F8;
  --placeholder: #AFB3C3;
}

.half{
  width: 100px;
  padding: 45px 10px 45px 30px;
  min-height: 100vh;
  overflow: hidden;
  z-index: 2;
  border-right: 1px solid hsl(228, 41%, 87%);
  background-color: #F3F4F8;
}

.half .brand_logo svg {
  width: 35px;
}

.toggle-icon{
  display: none;
}

.text-regular{
  color: #252525;
  font-weight: 400;
}

.text-medium{
  color: #545454;
  font-weight: 500;
}

.text-semibold{
  color: #545454;
  font-weight: 600;
}

.text-bold{
  color: #545454;
  font-weight: 700;
}

.text-heavy{
  color: #707070;
  font-weight: 800;
}

.bg-light{
  background-color: #FCFCFC !important;
}

form .form-control:focus{
  border-color: gainsboro;
  box-shadow: none;
}

.btn:focus,
.btn:active {
  border: none;
  outline: none;
  box-shadow: none;
}

.btn-check:focus,
.btn-primary, 
.btn-primary:focus {
  outline: none;
  box-shadow: none;
}

.form-check-input:focus{
  outline: none;
  box-shadow: none;
}

.HomePage h1,
  .MarketingBoardPage h1,
  .AppBoardPage h1,
  .InvoiceBoardPage h1,
  .RewardsAndPointPage h1,
  .SettingsPage h1,
  .DownloadableContentPage h1{
    font-size: 1.65rem;
  }

  .HomePage h2,
  .MarketingBoardPage h2,
  .AppBoardPage h2,
  .InvoiceBoardPage h2,
  .RewardsAndPointPage h2,
  .SettingsPage h2,
  .DownloadableContentPage h2{
    font-size: 1.45rem;
  }

  .HomePage h5,
  .MarketingBoardPage h5,
  .AppBoardPage h5,
  .InvoiceBoardPage h5,
  .RewardsAndPointPage h5,
  .SettingsPage h5,
  .DownloadableContentPage h5{
    font-size: 18px;
  }

  .HomePage h6,
  .MarketingBoardPage h6,
  .AppBoardPage h6,
  .InvoiceBoardPage h6,
  .RewardsAndPointPage h6,
  .SettingsPage h6,
  .DownloadableContentPage h6{
    font-size: 16px;
  }

  .HomePage p,
  .MarketingBoardPage p,
  .AppBoardPage p,
  .InvoiceBoardPage p,
  .RewardsAndPointPage p,
  .SettingsPage p,
  .DownloadableContentPage p{
    font-size: 14px;
  }

  .HomePage small,
  .MarketingBoardPage small,
  .AppBoardPage small,
  .InvoiceBoardPage small,
  .RewardsAndPointPage small,
  .SettingsPage small,
  .DownloadableContentPage small{
    font-size: 12px;
  }

  .SettingsPage .btns{
    font-size: 14px;
  }

  .DownloadableContentPage .btns{
    font-size: 14px;
  }

.PageWrap {
  display: flex;
}
#BrandLogo {
  enable-background:new 0 0 300 44;
}
#BrandLogo .st0{fill:#EF5123;}
#BrandLogo	.st1{fill:#F37A20;}
#BrandLogo	.st2{fill:#EE3824;}
#BrandLogo	.st3{fill:#000000;}

/* Side Nav Style Start */
aside {
  width: 375px;
  padding: 45px 10px 45px 30px;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  border-right: 1px solid #D2D7EC;
}

.brand_logo {
  width: 200px;
}
.brand_logo svg{
   width: 100%;
}
.SideNav {
  margin-top: 60px;
}
.SideNav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.SideNav ul li {
  margin: 20px 0;
}
.SideNav ul li a {
  display: flex;
  align-items: center;
  color: #545454;
  font-size: 20px;
  text-decoration: none;
  padding: 10px 0;
}
.SideNav ul li a svg {
  width: 30px;
  margin-right: 15px;
}
a.NavBottom {
  position: absolute;
  bottom: -20px;
}

.SideNav .active svg #IconHover *{
  fill: #F37A20;
}

/* Side Nav Style End */

/* Header Style Start */
.MainBody {
  width: 100%;
  padding: 40px;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header .icon{
  font-size: 25px;
  color: #545454;
  cursor: pointer;
}
.SearchField {
  width: 300px;
}
.SearchField input {
  height: 35px;
  background-color: transparent;
  border: none;
  width: 100%;
  font-size: 20px;
  color: #545454;
  padding-left: 35px;
  position: relative;
  overflow: hidden;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27.838' height='27.838' viewBox='0 0 27.838 27.838'%3E%3Cg id='Layer_2' transform='translate(-54.271 -51.741)'%3E%3Cg id='Group_68' data-name='Group 68' transform='translate(54.271 51.741)'%3E%3Cpath id='_1608826_search_icon' data-name='1608826_search_icon' d='M83.272,139.778a7.511,7.511,0,1,0-2.2,5.295A7.218,7.218,0,0,0,83.272,139.778ZM91.838,153.7a2.171,2.171,0,0,1-2.141,2.141,1.988,1.988,0,0,1-1.506-.636l-5.738-5.722a11.456,11.456,0,0,1-6.675,2.074,11.573,11.573,0,0,1-4.576-.928,11.534,11.534,0,0,1-6.274-6.274,11.738,11.738,0,0,1,0-9.151,11.534,11.534,0,0,1,6.274-6.274,11.738,11.738,0,0,1,9.151,0,11.534,11.534,0,0,1,6.274,6.274,11.573,11.573,0,0,1,.928,4.576,11.456,11.456,0,0,1-2.074,6.675l5.738,5.738A2.049,2.049,0,0,1,91.838,153.7Z' transform='translate(-64 -128)' fill='%23afb3c3'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: left center;
}
.SearchField input:focus {
  outline: none;
}
.SearchField input::placeholder {
  color: #AFB3C3;
}
.HeaderRight ul{
  padding: 0px;
  margin: 0px;
  list-style: none;
}
.HeaderRight ul li {
  display: inline-block;
}
.profileBtn:after {
  border: none !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.293' height='8.173' viewBox='0 0 14.293 8.173'%3E%3Cpath id='Icon_ionic-ios-arrow-down' data-name='Icon ionic-ios-arrow-down' d='M13.337,16.955l5.4-5.409a1.017,1.017,0,0,1,1.443,0,1.03,1.03,0,0,1,0,1.447L14.06,19.121a1.02,1.02,0,0,1-1.409.03L6.485,13a1.022,1.022,0,0,1,1.443-1.447Z' transform='translate(-6.188 -11.246)' fill='%23545454'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: right center;
  height: 8px;
  width: 15px;
}
.headerButton {
  background: transparent;
  border: none;
  background-color: transparent !important;
}
.profileBtn {
  display: flex;
  align-items: center;
}
.headerButton:hover, .headerButton:focus {
  background-color: transparent !important;
  border: none !important;
  outline: none;
  box-shadow: none !important;
}
.imgProfile {
  width: 31px;
  height: 31px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 6px;
}
.imgProfile img {
  width: 100%;
  height: 100%;
}
.profileBtn .imgProfile {
  margin-right: 6px;
}
.profileDropdown ul li {
  margin: 8px 0;
}
.notificationBtn {
  width: 27px;
  height: 31px;
  padding: 0;
  position: relative;
  margin-right: 20px;
}
.notificationBtn:after {
  border: none !important;
}
.notificationAlert:after {
  content: "";
  background-color: #ed7d3d;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  position: absolute;
  top: -5px;
  right: -5px;
  margin: 0;
}
.notificationBtn svg .onAlert{
  fill: #545454;
}
.notificationAlert svg .onAlert{
  fill: #ed7d3d;
}
.notificationDropdown {
  width: 467px;
}
.headerDropdown {
  padding: 30px;
  border-radius: 20px;
  border: none;
}
.notificationDropdown  ul li {
  display: block;
}
span.notificationHeading {
  font-size: 36px;
  color: #545454;
  display: block;
}
.notificationItem {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.headerDropdown a{
  font-size: 20px;
  color: #545454;
}
.notificationTime {
  color: #bbb;
}
.notifcationText span {
  display: block;
}
.notificationItem .imgProfile {
  margin-right: 10px;
}
/* Header Style End */


/* Dashboard */

.Dashboard h1{
  padding: 0;
  margin: 0;
}

.Dashboard h2{
  padding: 0 ;
  margin: 0;
}

.Dashboard h5{
  padding: 0;
  margin: 0;
}

.Dashboard h6{
  padding: 0;
  margin: 0;
}

.Dashboard p{
  padding: 0;
  margin: 0;
}

.Dashboard small{
  padding: 3px 15px;
  margin: 0;
}

.Dashboard .radius-20{
  border-radius: 20px;
}

.Dashboard .pink{
  background-color: var(--pink);
}

.Dashboard .orange{
  background-color: var(--orange);
}

.Dashboard .text-orange{
  color: var(--orange);
}

.Dashboard .progress-bar {
  background-color: var(--orange);
}

.Dashboard .progress{
  height: 12px;
  border-radius: 25px;
}

.Dashboard .h-150{
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Dashboard .h-350{
  max-height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Dashboard .h-185{
  height: 185px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Dashboard .h-card-185{
  height: 185px;
}

.Dashboard .btns{
  padding: 8px 15px;
  border: none;
  outline: none;
}

.Dashboard form .form-control:focus{
  border:1px solid gainsboro;
  box-shadow: none;
}

.Dashboard .MuiCircularProgress-determinate{
  width: 18px !important;
  margin-left: 2px;
}


.Dashboard .MuiDataGrid-iconSeparator {
  display: none !important;
}

.Dashboard .data-table{
  border: none !important;
}

.Dashboard .MuiDataGrid-columnHeaderTitle{
  color: #545454;
  font-weight: 600;
  font-size: 14px;
}

.Dashboard .user-img{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid white;
}

.Dashboard .MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}

.Dashboard .MuiDataGrid-selectedRowCount{
  visibility: hidden;
}

.Dashboard .-m8{
  margin-top: -8px !important;
}

.Dashboard .-m12{
  margin-top: -12px !important;
}

.Dashboard .-m5{
  margin-top: -5px !important;
}

.Dashboard .MuiDataGrid-cell {
  color: #545454;
  font-weight: 500;
  font-size: 14px;
}

/* Dashboard */

/* HomePage */

.HomePage .radius{
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.HomePage .carousel-item{
  padding: 10px;
}

.HomePage .card-container{
  box-shadow: 0 0 10px rgba(2,2,2,0.1);
}

.HomePage .carousel-control-prev-icon,
.HomePage .carousel-control-next,
.HomePage .carousel-control-prev{
  display: none;
}

.HomePage .carousel-indicators button{
  border-radius: 50% !important; 
  width: 10px !important;
  height: 10px !important;
  background-color: var(--orange); 
  position: relative;
  top: 38px;
}

/* HomePage */


/* MarketingBoardPage */

.MarketingBoardPage img{
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;

}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* MarketingBoardPage */


/* InvoiceBoardPage */

.InvoiceBoardPage .btn-paid,
.InvoiceBoardPage .btn-unpaid{
  border: none;
  outline: none;
  padding: 5px 15px;
  width: 120px;
  font-size: 16px;
}

.InvoiceBoardPage .btn-paid{
  color: white;
  background-color: var(--orange);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.InvoiceBoardPage .btn-unpaid{
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* InvoiceBoardPage */


/* Settings */

.SettingsPage input[type="date"] {
  color: var(--placeholder);
}

.SettingsPage .active{
  color: var(--orange) !important;
}

.SettingsPage .navlink{
  font-size: 16px;
}

.SettingsPage .navlink:hover{
  color: var(--orange);
}

.SettingsPage .btn-upload,
.SettingsPage .btn-remove{
  font-size: 14px;
  background-color: transparent;
}

.SettingsPage img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.SettingsPage .btn-upload{
  background-color: var(--orange);
  border-radius: 5px;
}

.SettingsPage .btn-remove{
  text-decoration: underline;
  color: var(--orange);
}

.SettingsPage input{
  border-radius: 10px;
  padding: 8px;
  font-size: 14px;
  background-color: var(--input);
}

.SettingsPage input::placeholder{
  color: var(--placeholder);
  font-size: 14px;
}

.SettingsPage .form-check-input{
  outline: none;
}

.SettingsPage .form-check-input:checked{
  background-color: var(--pink);
}

.SettingsPage .form-check-input{
  background-color: #CCCED6;
  border: 1px solid transparent;
}

.SettingsPage .form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23F37A20'/%3E%3C/svg%3E");
}

.SettingsPage .form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23F37A20'/%3E%3C/svg%3E");
}

/* Settings */


/* Downloadable Content */

.DownloadableContentPage .nav-tabs{
  background: #FCFCFC;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: none;
}

.DownloadableContentPage .nav-link.active{
  color: var(--orange);
  background-color:#FCFCFC;
  border: none;
}

.DownloadableContentPage .nav-link{
  color: #56576B;
}
.DownloadableContentPage .nav-link.active{
  color: var(--orange);
}

.DownloadableContentPage .nav-item button{
  border: none;
  outline: none;
}

.DownloadableContentPage .display-card .card-img{
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.DownloadableContentPage .display-card{
  border: 1px solid gainsboro;
}

.DownloadableContentPage .download-btn{
  width: 100%;
  border: none;
  outline: none;
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.DownloadableContentPage .icons{
  font-size: 18px;
}

/* Downloadable Content */


/* Rewards and Points */

.RewardsAndPointPage .radius{
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.RewardsAndPointPage .carousel-item{
  padding: 10px;
}

.RewardsAndPointPage .card-container{
  box-shadow: 0 0 10px rgba(2,2,2,0.1);
}

.RewardsAndPointPage .carousel-item{
  background: none !important;
}

.RewardsAndPointPage .carousel-control-prev-icon,
.RewardsAndPointPage .carousel-control-next,
.RewardsAndPointPage .carousel-control-prev{
  display: none;
}

.RewardsAndPointPage .carousel-indicators button{
  border-radius: 50% !important; 
  width: 10px !important;
  height: 10px !important;
  background-color: var(--orange); 
  position: relative;
  top: 38px;
}

.RewardsAndPointPage .left {
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.RewardsAndPointPage .box{
  overflow:"hidden";
  height: "108px";
}

/* Rewards and Points */


/* Error page */

.page_404{ 
  min-height: 100vh;
  padding:40px 0; 
  background:#fff; 
  font-family: 'Arvo', serif;
}

.page_404 img{ 
  width:100%;
}

.four_zero_four_bg{
    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
}
 
.four_zero_four_bg h1{
   font-size:80px;
}
 
.four_zero_four_bg h3{
	font-size:80px;
}

.page_404 .heading{
  color: black;
}
			 
.link_404{			 
	color: #fff!important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404{ 
  margin-top:-50px;
}

/* Error page */


/* input group */
.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  height: 50px;
  background-color: white;
  border-left: none;
}

.eye-icon{
  color: var(--placeholder);
  cursor: pointer;
}

.password-field{
  border-right: none;
}

/* input group */


/* Media Query */

@media screen and (max-width: 1280px){
  .column{
    display: flex !important;
    flex-direction: column !important ;
  }
}

@media screen and (min-width: 767px){
  .hide{
    display: none;
  }

  .w-md-50{
    width: 50% !important;
  }

  .mt-md-2{
    margin-top: 0 !important;
  }

  .ms-md-5{
    margin-left: 3rem;
  }
}

@media screen and (max-width: 991px) {
  .Logins {
    height: auto;
  }
}

@media screen and (max-width: 850px) {
  aside.show {
    width: 100%;
    z-index: 1000;
    padding: 45px 10px 45px 30px;
    height: 100%;
    position: fixed;
    background-color: var(--input);
    border-right: 1px solid #D2D7EC;
  }
  .toggle-icon{
    position: absolute;
    right: 0;
    font-size: 30px;
    display: block !important;
  }
  .half{
    display: none;
  }
  .MainBody {
    padding: 30px 25px;
  }
}

@media screen and (max-width: 767px) {
  .HomePage .radius{
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 0;
  }

  .RewardsAndPointPage .radius{
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
    border-bottom-left-radius: 0 !important; 
  }

  .RewardsAndPointPage .left{
    border-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
  }

  .RewardsAndPointPage .box{
    text-align: center;
  }

  .RewardsAndPointPage .box{
    overflow:"hidden";
    height: auto;
  }

  .Dashboard .h-card-185{
    height: auto;
  }

  .half{
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .Login .position-div{
    position: absolute;
    bottom: -40px !important;
    width: 100% !important;
    padding: 0 15px;
  }
}

@media screen and (max-width: 500px) {
  .Login .position-div .bottom{
    display: flex;
    flex-direction: column-reverse;
  }

  .Login .position-div .bottom p{
    margin: 0 !important;
  }
}

@media screen and (max-width:362px){
  .InvoiceBoardPage .btn-paid,
  .InvoiceBoardPage .btn-unpaid{
    border-radius: 0;
    width: 100%;
    border-radius: 10px;
    font-size: 14px;
    margin-bottom: 10px;
    padding: 8px;
  }
}

/* Media Query */
 
@media screen and (min-width: 1920px) {
  .HomePage h1,
  .MarketingBoardPage h1,
  .AppBoardPage h1,
  .InvoiceBoardPage h1,
  .RewardsAndPointPage h1,
  .SettingsPage h1,
  .DownloadableContentPage h1{
    font-size: 36px;
  }

  .HomePage h2,
  .MarketingBoardPage h2,
  .AppBoardPage h2,
  .InvoiceBoardPage h2,
  .RewardsAndPointPage h2,
  .SettingsPage h2,
  .DownloadableContentPage h2{
    font-size: 1.45rem;
  }

  .HomePage h5,
  .MarketingBoardPage h5,
  .AppBoardPage h5,
  .InvoiceBoardPage h5,
  .RewardsAndPointPage h5,
  .SettingsPage h5,
  .DownloadableContentPage h5{
    font-size: 24px;
  }

  .HomePage h6,
  .MarketingBoardPage h6,
  .AppBoardPage h6,
  .InvoiceBoardPage h6,
  .RewardsAndPointPage h6,
  .SettingsPage h6,
  .DownloadableContentPage h6{
    font-size: 20px;
  }

  .HomePage p,
  .MarketingBoardPage p,
  .AppBoardPage p,
  .InvoiceBoardPage p,
  .RewardsAndPointPage p,
  .SettingsPage p,
  .DownloadableContentPage p{
    font-size: 18px;
  }

  .HomePage small,
  .MarketingBoardPage small,
  .AppBoardPage small,
  .InvoiceBoardPage small,
  .RewardsAndPointPage small,
  .SettingsPage small,
  .DownloadableContentPage small{
    font-size: 18px;
  }

  .SettingsPage .btns{
    font-size: 14px;
  }

  .DownloadableContentPage .btns{
    font-size: 14px;
  }
}



/* Logins */

.Login h1{
  font-size: 1.65rem;
  margin: 0;
  padding: 0;
}

.Login p{
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.Login .left{
  background-image: url('./assets/images/logins-left-bg.png');
  background-size: cover;
  min-height: 100vh;
  width: 40%;
}

.Login .right{
  background-color: #F3F4F8;
  min-height: 100vh;
  width: 60%;
  position: relative;
}

.Login .input{
  font-size: 14px;
  height: 50px;
}

.Login input::placeholder{
  font-size: 14px;
  color: var(--placeholder);
}

.Login .btn-submit{
  font-size: 14px;
  height: 50px;
  background-color: var(--orange);
  outline: none;
  border: none;
}

.Login input[type="date"] {
  color: var(--placeholder);
}

.Login .text-orange{
  color: var(--orange);
  text-decoration: underline;
}

.Login .forms{
  position: relative;
}

.LoginListing {
  list-style: none;
  margin: 0;
  padding: 0;
}

.LoginListing li {
  font-size: 20px;
  color: #fff;
  line-height : 45px;
  padding-left: 40px;
  position: relative;
}
.LoginListing li:before {
  content: "\f00c";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  top: 2px;
  left: 0;
  color: #fff;
  font-size: 22px;
}

.Login .position-div{
  position: absolute;
  bottom: 20px;
  width: 82%;
}

.error{
  position: relative;
  top: -4px;
  left: 2px;
  color: red;
}

.error-setting{
  position: relative;
  left: -10px;
  color: red;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root{
  box-shadow: none !important;
  font-size: 14px !important;
  font-family: 'SF Pro Display Regular' !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  box-shadow: none !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root:hover{
  background-color: var(--orange) !important;
}

@media screen and (max-width: 991px) {
  .Login .left{
    width: 100%;
  }
  
  .Login .right{
    width: 100%;
  }

  .right{
    padding: 20px 0;
  }
}

@media screen and (max-width: 320px){
  .Login .logo{
    width: 100%;
  }
}

/* Logins */